import React from 'react'

import '../../lang/i18n';
import { useTranslation } from 'react-i18next';
import { LanguajeBar } from './LanguajeBar';

const Index = () => {
  //372 x 533
  const { t, i18n } = useTranslation();
  const getLanguage = () => i18n.language || window.localStorage.i18n; // get current languaje

  return (
    <>
      <section className="py-16 overflow-hidden">
        <div className="container px-8 mx-auto">
          <div className="flex flex-wrap lg:items-center -m-8 lg:-m-14">
            <div className="w-full md:w-1/2 p-8 lg:p-14">
              <div className="relative max-w-max mx-auto lg:ml-auto lg:mr-0 overflow-hidden rounded-4xl">
                <img className="me-large-img transform hover:scale-105 transition ease-in-out duration-1000" src="src/img/me_large.jpg" alt="me_large" />
                <div className="absolute bottom-0 left-0 w-full px-16 pb-10">
                  <div className="overflow-hidden rounded-lg" style={{backdropFilter: 'blur(8px)'}}>
                    <div className="flex flex-wrap sm:divide-x divide-y sm:divide-y-0 divide-gray-300">
                      <div className="w-1/3">
                        <a className="flex justify-center items-center h-full py-6 bg-white bg-opacity-80 hover:bg-opacity-60 transition ease-in-out duration-200" href="https://www.facebook.com/EduardoLeroAraya" target='_blank' rel="noreferrer">
                          <img src='src/img/b-facebook.png' className='img-social-am' alt='facebook_link' />
                        </a>
                      </div>
                      <div className="w-1/3">
                        <a className="flex justify-center items-center h-full py-6 bg-white bg-opacity-80 hover:bg-opacity-60 transition ease-in-out duration-200" href="https://github.com/Leroncio" target='_blank' rel="noreferrer">
                          <img src='src/img/b-github.png' className='img-social-am' alt='github_link' />
                        </a>
                      </div>
                      <div className="w-1/3">
                        <a className="flex justify-center items-center h-full py-6 bg-white bg-opacity-80 hover:bg-opacity-60 transition ease-in-out duration-200" href="https://www.linkedin.com/in/arayacortesedu" target='_blank' rel="noreferrer">
                        <img src='src/img/b-linkedin.png' className='img-social-am' alt='linkedin_link' />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 p-8 lg:p-14">
              <div className="md:max-w-sm text-left">
                <p className="mb-8 font-sans text-sm text-indigo-600 font-semibold uppercase tracking-px">{t('hello_world')}</p>
                <h2 className="mb-8 text-6xl md:text-8xl xl:text-10xl font-bold font-heading tracking-px-n leading-none">{t('my_name')}</h2>
                <p className="mb-7 text-lg text-gray-900 font-medium">{t('about_me_content')}</p>
                <p className="text-gray-600 font-medium">{t('about_me_quote')}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-16 bg-white overflow-hidden">
        <div className="container px-8 mx-auto">
          <h2 className="mb-4 text-6xl md:text-7xl font-bold font-heading tracking-px-n leading-tight">{t('about_me_formation')}</h2>
          <p className="mb-20 text-lg text-gray-900 font-medium">{t('about_me_subtitle')}</p>
          <div className="flex flex-wrap -m-8">
            <div className="w-full md:w-1/2 p-8">
              <div className="flex flex-wrap sm:items-center -m-4">
                <div className="w-full sm:w-auto p-4">
                  <div className="inline-block max-w-max mx-auto overflow-hidden rounded-xl">
                    <img className="locale-img transform hover:scale-105 transition ease-in-out duration-1000" src="src/img/santo-tomas.jpg" alt="santotomas" />
                  </div>
                </div>
                <div className="w-full sm:flex-1 p-4">
                  <div className="sm:w-64">
                    <h3 className="mb-4 text-lg font-bold font-heading leading-snug">Santo Tomas</h3>
                    <p className="mb-4 font-medium leading-relaxed">{t('santo_tomas_info')}</p>
                    <a className="font-bold font-heading" href='https://www.ipsantotomas.cl/sedes-ip/ovalle/' target='_blank' rel="noreferrer">{t('visit_website')}</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 p-8">
              <div className="flex flex-wrap sm:items-center -m-4">
                <div className="w-full sm:w-auto p-4">
                  <div className="inline-block max-w-max mx-auto overflow-hidden rounded-xl">
                    <img className="locale-img transform hover:scale-105 transition ease-in-out duration-1000" src="src/img/udemy.jpg" alt="udemy" />
                  </div>
                </div>
                <div className="w-full sm:flex-1 p-4">
                  <div className="sm:w-64">
                    <h3 className="mb-4 text-lg font-bold font-heading leading-snug">Udemy</h3>
                    <p className="mb-4 font-medium leading-relaxed">{t('udemy_info')}</p>
                    <a className="font-bold font-heading" href='https://www.udemy.com/'  target='_blank' rel="noreferrer">{t('visit_website')}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-16 bg-white overflow-hidden">
        <div className="container px-8 mx-auto">
          <h2 className="mb-4 text-6xl md:text-7xl font-bold font-heading tracking-px-n leading-tight">{t('my_skills')}</h2>
          <p className="mb-20 text-lg text-gray-900 font-medium">{t('about_skills')}</p>
          <div className="flex flex-wrap -m-8">
            <div className="w-full md:w-1/2 p-8">
              <img className="transform hover:translate-x-16 transition ease-in-out duration-1000" src={(getLanguage() === "es") ? 'src/img/quote_es.png' : 'src/img/quote_en.png'} alt="quote" />
            </div>
            <div className="w-full md:w-1/2 p-8">
              <div className="flex">
                <div className='md:w-1/2'>
                <div className="flex flex-wrap">

                  <LanguajeBar languaje="php" percent="95%" barColor='#00bcd4' barAccent='#3fd2e5' />
                  <LanguajeBar languaje="csharp" percent="95%" barColor='#00c853' barAccent='#69f0ae'  />
                  <LanguajeBar languaje="react" percent="75%" barColor='#80deea' barAccent='#a6e0e8' />
                  <LanguajeBar languaje="java" percent="90%" barColor='#1867c0' barAccent='#5591d6' />
                  <LanguajeBar languaje="node" percent="60%" barColor='#4caf50' barAccent='#69b86c' />

                </div>
                </div>
                <div className='md:w-1/2'>
                <div className="flex flex-wrap">

                  <LanguajeBar languaje="html" percent="100%" barColor='#e65100' barAccent='#ff6d00' />
                  <LanguajeBar languaje="javascript" percent="95%" barColor='#ffd600' barAccent='#ead569' />
                  <LanguajeBar languaje="css" percent="85%" barColor='#0277bd' barAccent='#039be5' />
                  <LanguajeBar languaje="api" percent="95%" barColor='#78909c' barAccent='#455a64' />
                  <LanguajeBar languaje="json" percent="100%" barColor='#cfd95b' barAccent='#a3aa56' />
                </div>
                </div>

              </div>
            </div>
          </div>

          <div className="flex flex-wrap -m-8 text-left">
            <div className="w-full md:w-1/2 p-8">
              <h2 className="mb-16 text-6xl md:text-7xl font-bold font-heading tracking-px-n leading-tight">{t('my_strengths')}</h2>
              <div className="w-auto p-y-4">
                <div className="flex flex-wrap -m-2">
                  <div className="flex-1 p-2">
                    <h3 className="text-lg font-semibold">{t('strength_one_title')}</h3>
                  </div>
                  <div className="w-full p-2">
                    <div className="pb-8 border-b">
                      <p className="text-gray-600 font-medium leading-relaxed">{t('strength_one_description')}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-auto p-y-4">
                <div className="flex flex-wrap -m-2">
                  <div className="flex-1 p-2">
                    <h3 className="text-lg font-semibold">{t('strength_two_title')}</h3>
                  </div>
                  <div className="w-full p-2">
                    <div className="pb-8 border-b">
                      <p className="text-gray-600 font-medium leading-relaxed">{t('strength_two_description')}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-auto p-y-4">
                <div className="flex flex-wrap -m-2">
                  <div className="flex-1 p-2">
                    <h3 className="text-lg font-semibold">{t('strength_three_title')}</h3>
                  </div>
                  <div className="w-full p-2">
                    <div className="pb-8 border-b">
                      <p className="text-gray-600 font-medium leading-relaxed">{t('strength_three_description')}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-auto p-y-4">
                <div className="flex flex-wrap -m-2">
                  <div className="flex-1 p-2">
                    <h3 className="text-lg font-semibold">{t('strength_four_title')}</h3>
                  </div>
                  <div className="w-full p-2">
                    <div className="pb-8 border-b">
                      <p className="text-gray-600 font-medium leading-relaxed">{t('strength_four_description')}</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="w-full md:w-1/2 p-8">
              <h2 className="mb-16 text-6xl md:text-7xl font-bold font-heading tracking-px-n leading-tight">{t('my_weaknesses')}</h2>
              <div className="w-auto p-y-4">
                <div className="flex flex-wrap -m-2">
                  <div className="flex-1 p-2">
                    <h3 className="text-lg font-semibold">{t('weaknesses_one_title')}</h3>
                  </div>
                  <div className="w-full p-2">
                    <div className="pb-8 border-b">
                      <p className="text-gray-600 font-medium leading-relaxed">{t('weaknesses_one_description')}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-auto p-y-4">
                <div className="flex flex-wrap -m-2">
                  <div className="flex-1 p-2">
                    <h3 className="text-lg font-semibold">{t('weaknesses_two_title')}</h3>
                  </div>
                  <div className="w-full p-2">
                    <div className="pb-8 border-b">
                      <p className="text-gray-600 font-medium leading-relaxed">{t('weaknesses_one_description')}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-auto p-y-4">
                <div className="flex flex-wrap -m-2">
                  <div className="flex-1 p-2">
                    <h3 className="text-lg font-semibold">{t('weaknesses_three_title')}</h3>
                  </div>
                  <div className="w-full p-2">
                    <div className="pb-8 border-b">
                      <p className="text-gray-600 font-medium leading-relaxed">{t('weaknesses_three_description')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    </>
  )
}

export default Index;
