import React from 'react'

import '../../lang/i18n';
import { useTranslation } from 'react-i18next';

export const Hero = () => {

  const { t } = useTranslation();

  return (
    <div>
        <div className="relative z-20 overflow-hidden pt-12 pb-28">
          <div className="container px-4 mx-auto">
            <div className="flex flex-wrap xl:items-center -m-8">
              <div className="w-full md:w-2/6 xl:w-2/6 p-8 xl:p-12">
                <div className="md:inline-block relative">
                  <div className="overflow-hidden rounded-lg">
                    <img className="w-full md:w-auto rounded-lg transform hover:scale-105 transition ease-in-out duration-1000" src="src/img/me.jpg" alt="" data-config-id="auto-img-3-3" />
                  </div>
                  <div className="p-8 absolute bottom-0 left-0 w-full">
                    <div className="p-6 bg-black bg-opacity-70 backdrop-blur-xl rounded-lg">
                      <p className="text-sm text-white text-opacity-60 font-semibold uppercase tracking-px" data-config-id="auto-txt-13-3">{t('programmer_analyst')}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-4/6 xl:flex-1 p-8 xl:p-12">
                <div className="xl:max-w-2xl">
                  <h1 className="mb-7 text-left text-6xl md:text-8xl xl:text-10xl font-bold font-heading tracking-px-n leading-none" data-config-id="auto-txt-14-3">{t('title_presentation')}</h1>
                  <p className="mb-9 text-left text-lg text-gray-700 font-medium" data-config-id="auto-txt-15-3">{t('presentation')}</p>
                  { /* 
                  <div className="flex flex-wrap items-center -m-16">
                    <div className="w-auto p-16">
                      <div className="flex flex-wrap -m-0.5 mb-3">
                        <div className="w-auto p-0.5">
                          <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-config-id="auto-svg-3-3">
                            <path d="M7.16159 1.21266C7.48271 0.224341 8.88091 0.224342 9.20203 1.21266L10.3494 4.74386C10.493 5.18585 10.9049 5.48509 11.3696 5.48509H15.0825C16.1217 5.48509 16.5538 6.81486 15.7131 7.42568L12.7093 9.60808C12.3333 9.88124 12.176 10.3654 12.3196 10.8074L13.4669 14.3386C13.788 15.3269 12.6569 16.1488 11.8162 15.538L8.81234 13.3556C8.43637 13.0824 7.92725 13.0824 7.55128 13.3556L4.54746 15.538C3.70675 16.1488 2.57558 15.3269 2.8967 14.3386L4.04406 10.8074C4.18767 10.3654 4.03034 9.88124 3.65437 9.60808L0.650549 7.42567C-0.190162 6.81486 0.241906 5.48509 1.28108 5.48509H4.99401C5.45874 5.48509 5.87062 5.18585 6.01423 4.74386L7.16159 1.21266Z" fill="#00B67A"></path>
                          </svg>
                        </div>
                        <div className="w-auto p-0.5">
                          <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-config-id="auto-svg-4-3">
                            <path d="M7.16159 1.21266C7.48271 0.224341 8.88091 0.224342 9.20203 1.21266L10.3494 4.74386C10.493 5.18585 10.9049 5.48509 11.3696 5.48509H15.0825C16.1217 5.48509 16.5538 6.81486 15.7131 7.42568L12.7093 9.60808C12.3333 9.88124 12.176 10.3654 12.3196 10.8074L13.4669 14.3386C13.788 15.3269 12.6569 16.1488 11.8162 15.538L8.81234 13.3556C8.43637 13.0824 7.92725 13.0824 7.55128 13.3556L4.54746 15.538C3.70675 16.1488 2.57558 15.3269 2.8967 14.3386L4.04406 10.8074C4.18767 10.3654 4.03034 9.88124 3.65437 9.60808L0.650549 7.42567C-0.190162 6.81486 0.241906 5.48509 1.28108 5.48509H4.99401C5.45874 5.48509 5.87062 5.18585 6.01423 4.74386L7.16159 1.21266Z" fill="#00B67A"></path>
                          </svg>
                        </div>
                        <div className="w-auto p-0.5">
                          <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-config-id="auto-svg-5-3">
                            <path d="M7.16159 1.21266C7.48271 0.224341 8.88091 0.224342 9.20203 1.21266L10.3494 4.74386C10.493 5.18585 10.9049 5.48509 11.3696 5.48509H15.0825C16.1217 5.48509 16.5538 6.81486 15.7131 7.42568L12.7093 9.60808C12.3333 9.88124 12.176 10.3654 12.3196 10.8074L13.4669 14.3386C13.788 15.3269 12.6569 16.1488 11.8162 15.538L8.81234 13.3556C8.43637 13.0824 7.92725 13.0824 7.55128 13.3556L4.54746 15.538C3.70675 16.1488 2.57558 15.3269 2.8967 14.3386L4.04406 10.8074C4.18767 10.3654 4.03034 9.88124 3.65437 9.60808L0.650549 7.42567C-0.190162 6.81486 0.241906 5.48509 1.28108 5.48509H4.99401C5.45874 5.48509 5.87062 5.18585 6.01423 4.74386L7.16159 1.21266Z" fill="#00B67A"></path>
                          </svg>
                        </div>
                        <div className="w-auto p-0.5">
                          <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-config-id="auto-svg-6-3">
                            <path d="M7.16159 1.21266C7.48271 0.224341 8.88091 0.224342 9.20203 1.21266L10.3494 4.74386C10.493 5.18585 10.9049 5.48509 11.3696 5.48509H15.0825C16.1217 5.48509 16.5538 6.81486 15.7131 7.42568L12.7093 9.60808C12.3333 9.88124 12.176 10.3654 12.3196 10.8074L13.4669 14.3386C13.788 15.3269 12.6569 16.1488 11.8162 15.538L8.81234 13.3556C8.43637 13.0824 7.92725 13.0824 7.55128 13.3556L4.54746 15.538C3.70675 16.1488 2.57558 15.3269 2.8967 14.3386L4.04406 10.8074C4.18767 10.3654 4.03034 9.88124 3.65437 9.60808L0.650549 7.42567C-0.190162 6.81486 0.241906 5.48509 1.28108 5.48509H4.99401C5.45874 5.48509 5.87062 5.18585 6.01423 4.74386L7.16159 1.21266Z" fill="#00B67A"></path>
                          </svg>
                        </div>
                        <div className="w-auto p-0.5">
                          <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-config-id="auto-svg-7-3">
                            <path d="M7.16159 1.21266C7.48271 0.224341 8.88091 0.224342 9.20203 1.21266L10.3494 4.74386C10.493 5.18585 10.9049 5.48509 11.3696 5.48509H15.0825C16.1217 5.48509 16.5538 6.81486 15.7131 7.42568L12.7093 9.60808C12.3333 9.88124 12.176 10.3654 12.3196 10.8074L13.4669 14.3386C13.788 15.3269 12.6569 16.1488 11.8162 15.538L8.81234 13.3556C8.43637 13.0824 7.92725 13.0824 7.55128 13.3556L4.54746 15.538C3.70675 16.1488 2.57558 15.3269 2.8967 14.3386L4.04406 10.8074C4.18767 10.3654 4.03034 9.88124 3.65437 9.60808L0.650549 7.42567C-0.190162 6.81486 0.241906 5.48509 1.28108 5.48509H4.99401C5.45874 5.48509 5.87062 5.18585 6.01423 4.74386L7.16159 1.21266Z" fill="#00B67A"></path>
                          </svg>
                        </div>
                      </div>
                      <div className="flex flex-wrap -m-0.5 mb-2.5">
                        <div className="w-auto p-0.5">
                          <p className="text-gray-900 font-bold" data-config-id="auto-txt-17-3">4.2/5</p>
                        </div>
                        <div className="w-auto p-0.5">
                          <p className="text-gray-600 font-medium" data-config-id="auto-txt-18-3">(45k Reviews)</p>
                        </div>
                      </div>
                      <img src="flaro-assets/logos/brands/brand-trustpilot.png" alt="" data-config-id="auto-img-7-3" />
                    </div>
                    <div className="w-auto">
                      <div className="h-16 w-px bg-gray-200"></div>
                    </div>
                    <div className="w-auto p-16">
                      <div className="flex flex-col justify-between">
                        <div className="flex flex-wrap -m-0.5 mb-3">
                          <div className="w-auto p-0.5">
                            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-config-id="auto-svg-8-3">
                              <path d="M7.707 1.21266C8.02812 0.224341 9.42632 0.224342 9.74744 1.21266L10.8948 4.74386C11.0384 5.18585 11.4503 5.48509 11.915 5.48509H15.6279C16.6671 5.48509 17.0992 6.81486 16.2585 7.42568L13.2547 9.60808C12.8787 9.88124 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.3269 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.3269 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88124 4.19978 9.60808L1.19596 7.42567C0.355248 6.81486 0.787317 5.48509 1.82649 5.48509H5.53942C6.00415 5.48509 6.41603 5.18585 6.55964 4.74386L7.707 1.21266Z" fill="#FF9D28"></path>
                            </svg>
                          </div>
                          <div className="w-auto p-0.5">
                            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-config-id="auto-svg-9-3">
                              <path d="M7.707 1.21266C8.02812 0.224341 9.42632 0.224342 9.74744 1.21266L10.8948 4.74386C11.0384 5.18585 11.4503 5.48509 11.915 5.48509H15.6279C16.6671 5.48509 17.0992 6.81486 16.2585 7.42568L13.2547 9.60808C12.8787 9.88124 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.3269 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.3269 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88124 4.19978 9.60808L1.19596 7.42567C0.355248 6.81486 0.787317 5.48509 1.82649 5.48509H5.53942C6.00415 5.48509 6.41603 5.18585 6.55964 4.74386L7.707 1.21266Z" fill="#FF9D28"></path>
                            </svg>
                          </div>
                          <div className="w-auto p-0.5">
                            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-config-id="auto-svg-10-3">
                              <path d="M7.707 1.21266C8.02812 0.224341 9.42632 0.224342 9.74744 1.21266L10.8948 4.74386C11.0384 5.18585 11.4503 5.48509 11.915 5.48509H15.6279C16.6671 5.48509 17.0992 6.81486 16.2585 7.42568L13.2547 9.60808C12.8787 9.88124 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.3269 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.3269 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88124 4.19978 9.60808L1.19596 7.42567C0.355248 6.81486 0.787317 5.48509 1.82649 5.48509H5.53942C6.00415 5.48509 6.41603 5.18585 6.55964 4.74386L7.707 1.21266Z" fill="#FF9D28"></path>
                            </svg>
                          </div>
                          <div className="w-auto p-0.5">
                            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-config-id="auto-svg-11-3">
                              <path d="M7.707 1.21266C8.02812 0.224341 9.42632 0.224342 9.74744 1.21266L10.8948 4.74386C11.0384 5.18585 11.4503 5.48509 11.915 5.48509H15.6279C16.6671 5.48509 17.0992 6.81486 16.2585 7.42568L13.2547 9.60808C12.8787 9.88124 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.3269 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.3269 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88124 4.19978 9.60808L1.19596 7.42567C0.355248 6.81486 0.787317 5.48509 1.82649 5.48509H5.53942C6.00415 5.48509 6.41603 5.18585 6.55964 4.74386L7.707 1.21266Z" fill="#FF9D28"></path>
                            </svg>
                          </div>
                          <div className="w-auto p-0.5">
                            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-config-id="auto-svg-12-3">
                              <path d="M7.707 1.21266C8.02812 0.224341 9.42632 0.224342 9.74744 1.21266L10.8948 4.74386C11.0384 5.18585 11.4503 5.48509 11.915 5.48509H15.6279C16.6671 5.48509 17.0992 6.81486 16.2585 7.42568L13.2547 9.60808C12.8787 9.88124 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.3269 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.3269 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88124 4.19978 9.60808L1.19596 7.42567C0.355248 6.81486 0.787317 5.48509 1.82649 5.48509H5.53942C6.00415 5.48509 6.41603 5.18585 6.55964 4.74386L7.707 1.21266Z" fill="#FF9D28"></path>
                            </svg>
                          </div>
                        </div>
                        <div className="flex flex-wrap -m-0.5 mb-4">
                          <div className="w-auto p-0.5">
                            <p className="text-gray-900 font-bold" data-config-id="auto-txt-19-3">4.1/5</p>
                          </div>
                          <div className="w-auto p-0.5">
                            <p className="text-gray-600 font-medium" data-config-id="auto-txt-20-3">(18k Reviews)</p>
                          </div>
                        </div>
                      </div>
                      <img src="flaro-assets/logos/brands/brand-capterra.png" alt="" data-config-id="auto-img-8-3" />
                    </div>
                  </div>
                  */}
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}
