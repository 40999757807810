import './App.css';
import './Stylesheets/tailwind/tailwind.min.css';
import {  Routes, Route } from 'react-router-dom';

import Home from './views/Home';
import AboutMe from './views/AboutMe';
import Contact from './views/Contact';

import Error404 from './views/Error/404';

import { Header as MainHeader} from './components/Header';
import { Footer } from './components/Footer';

function App() {
  return (
    <div className="App">
        <Routes>
                <Route path="/" element={<MainHeader />}>
                  <Route index element={<Home />} />
                  <Route path="about-me" element={<AboutMe />} />
                  <Route path="contact" element={<Contact />} />
                  <Route path="*" element={<Error404 />} />
                </Route>
        </Routes>

        <Footer />
  </div>
  )
}

export default App;
